import * as React from "react";
import { HeadFC } from "gatsby";
import Header_Layout from "../components/layouts/Header_Layout";
import "../styles/main.css";
import Add_Build_Details from "../components/add_build/Add_Build_Details";
import { useState } from "react";
import Add_Build_Basics from "../components/add_build/Add_Build_Basics";
import Add_Build_Usage from "../components/add_build/Add_Build_Usage";
import { Dayjs } from "dayjs";
import { Box, Container, LinearProgress } from "@mui/material";

const page_styles = {
  color: "#232129",
  padding: '1rem 0',
  fontFamily: "Ubuntu, Roboto, sans-serif, serif",
};

const CreatePage = () => {
  const TOTAL_STEPS = 3;
  const [step, set_step] = useState(1);
  const [date_of_purchase, set_date_of_purchase] = React.useState<Dayjs | null>(
    null
  );
  const [selected_usage_options, set_selected_usage_options] = useState<
    string[] | null
  >([]);

  return (
    <Header_Layout fullWidth>
      <Box sx={{ width: "100%" }}>
        <LinearProgress
          variant="determinate"
          value={((step - 1) / TOTAL_STEPS) * 100}
        />
      </Box>
      <Container sx={page_styles}>
        {step === 1 && (
          <Add_Build_Basics
            step_handler={set_step}
            value_handler={set_date_of_purchase}
          />
        )}
        {step === 2 && (
          <Add_Build_Usage
            step_handler={set_step}
            value_handler={set_selected_usage_options}
          />
        )}
        {step === 3 && (
          <Add_Build_Details
            date_of_purchase={date_of_purchase ?? undefined}
            selected_usage_options={selected_usage_options ?? undefined}
          />
        )}
      </Container>
    </Header_Layout>
  );
};

export default CreatePage;

export const Head: HeadFC = () => <title>Create a build - BitLot.app</title>;
